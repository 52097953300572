const getUserDetails = (state) => {
    let user_details = state.ud
    if(user_details) {
        user_details = JSON.parse(user_details)
    }
	return user_details
}

const getUserNavigation = (state) => {
    let user_navigation = JSON.parse(state.un)
    return user_navigation;
}

const getUserModule = (state) => {
    let modules = [];
    if(JSON.parse(state.un)) {
        for(let i = 0; i < JSON.parse(state.un).length; i++) {
            if(JSON.parse(state.un)[i].modules_view.length > 0) {
                for(let j = 0; j < JSON.parse(state.un)[i].modules_view.length; j++) {
                    modules.push(JSON.parse(state.un)[i].modules_view[j])
                }
            }
        }
    }
    return modules;
}

const can = (state) => {
    return (request) => {
        let user_permission = JSON.parse(state.up ? state.up : [])
        return user_permission.includes(request)
    }
}

const getTokenExpiration = (state) => {
    let token_expiration = state.te
	return token_expiration
}

const getAppNavigation = (state) => {
    let app_navigation = state.an
    return JSON.parse(app_navigation)
}

const getUserToken = (state) => {
    let user_token = state.ut
    return user_token
}

const getOrganizationName = (state) => {
    let organization_name = state.on
    return organization_name ? organization_name.toLowerCase() : 'invalid'
}

const getOrganizationKey = (state) => {
    let organization_key = state.ok
    return organization_key
}

const getOrganizationLogo = (state) => {
    let organization_logo = state.ol
    return organization_logo
}

const getUserNotifications = (state) => {
    let notification_data = JSON.parse(state.uf)
    return notification_data
}

const getLoaded = (state) => {
    return state.loaded
}

const getAsideShowValue = (state) => {
    return state.asideShow;
}

const getCurrentlyUserName = (state) => {
    let user_data = JSON.parse(state.ud)
    return `${user_data.first_name} ${user_data.last_name}${user_data.suffix ? ' '+user_data.suffix: ''}`
}

const getPdfBase64 = (state) => {
    return state.formDataPdfBase64 ? state.formDataPdfBase64 : null;
}

const getPdfReferenceNumber = (state) => {
    return state.formDataPdfRoutingRefNum ? state.formDataPdfRoutingRefNum : null;
}

export default {
    getUserDetails,
    getUserNavigation,
    getUserModule,
    can,
    getTokenExpiration,
    getAppNavigation,
    getUserToken,
    getOrganizationName,
    getOrganizationKey,
    getOrganizationLogo,
    getUserNotifications,
    getLoaded,
    getAsideShowValue,
    getPdfBase64,
    getPdfReferenceNumber,
    getCurrentlyUserName,
}